import { Button, Card, Col, Divider, Form, Input, Modal, Row, Spin, Tree } from 'antd';
import { FDSelection, FDTreeTransfer, FDUploadImage } from 'common/components';
import { COMPANY_TYPE, SUCCESSFULLY_EDIT_MESSAGE } from 'common/constants';
import { alertErrorMessage, alertSuccessMessage, normFile } from 'common/utils';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
    getServiceBoatsSelection,
    getSitesContainingPens,
    getUsers,
    updateUser
} from 'redux/thunks';

const UserForm = ({ open = false, user, onClose = () => {} }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const dispatch = useDispatch();

    const updateLoading = useSelector((s) => s.user.loading);
    const companiesSelection = useSelector((s) => s.companySelection.data);
    const siteContainingPens = useSelector((s) => s.sitePen.data);
    const { data: serviceBoatSelection } = useSelector((s) => s.serviceBoatSelection);

    // data for loading site and pen to form assign site/pen
    const [penIds, setPenIds] = useState([]);
    const [treeData, setTreeData] = useState([]);
    const [serviceBoatSelectionData, setServiceBoatSelectionData] = useState([]);
    const [companyTypes, setCompanyTypes] = useState([]);
    const [externalServiceBoatIds, setExternalServiceBoatIds] = useState([]);

    useEffect(() => {
        dispatch(getSitesContainingPens());
        dispatch(getServiceBoatsSelection());
    }, []);

    useEffect(() => {
        form.setFieldsValue(user);

        if (user) {
            setPenIds(user.sites?.flatMap((site) => site.pens?.map((pen) => pen.id)));
            setTreeData(filterSitesByCompany(user.organizationId));
            setServiceBoatSelectionData(filterServiceBoatsByCompany(user.organizationId));
            setCompanyTypes(user.company?.companyTypes);
            setExternalServiceBoatIds(user.externalServiceBoatIds);
        }
    }, [user]);

    // data for tree assign site/pen
    const filterSitesByCompany = (organizationId) => {
        return siteContainingPens
            .filter((site) => site.organizationId === organizationId && site.pens?.length)
            .map((site) => ({
                key: site.id,
                title: site.siteName,
                children: site.pens
                    ?.filter((pen) => pen.organizationId === organizationId)
                    .map((pen) => ({
                        key: pen.id,
                        title: pen.penNumber
                    }))
            }));
    };

    const filterServiceBoatsByCompany = (organizationId) => {
        return serviceBoatSelection
            .filter((sb) => sb.organizationId === organizationId)
            .map((sb) => ({
                key: sb.id,
                title: sb.text
            }));
    };

    const handleCompanyChange = (value) => {
        setTreeData(filterSitesByCompany(value));
        setServiceBoatSelectionData(filterServiceBoatsByCompany(value));
        setPenIds([]);
        setExternalServiceBoatIds([]);
        setCompanyTypes(companiesSelection.find((company) => company.id === value).companyTypes);
    };

    const handleUpdateUser = async (req) => {
        try {
            req.penIds = penIds; //set pend ids to form req
            req.externalServiceBoatIds = externalServiceBoatIds;
            await dispatch(updateUser({ id: user.id, item: req })).unwrap();

            dispatch(getUsers());
            alertSuccessMessage(t(SUCCESSFULLY_EDIT_MESSAGE));
            onClose();
        } catch (err) {
            alertErrorMessage(err, t);
        }
    };

    const handleCancel = () => {
        form.resetFields();
        onClose();
    };

    return (
        <Modal
            title={t('user.userForm.title')}
            open={open}
            onCancel={handleCancel}
            width={1000}
            footer={
                <>
                    <Button type="default" onClick={handleCancel} className="m-1">
                        {t('general.form.cancel')}
                    </Button>
                    <Button
                        onClick={form.submit}
                        className="m-1 bg-sky-700 hover:bg-sky-800 text-white hover:text-white"
                    >
                        {t('general.form.saveChange')}
                    </Button>
                </>
            }
        >
            <Spin spinning={updateLoading}>
                <Form
                    name="basic"
                    labelCol={{ span: 6 }}
                    wrapperCol={{
                        xs: {
                            span: 24,
                            offset: 0
                        },
                        sm: {
                            span: 24,
                            offset: 2
                        }
                    }}
                    onFinish={handleUpdateUser}
                    autoComplete="off"
                    form={form}
                    className="create-user-form"
                >
                    <Row gutter={24}>
                        <Col xs={24} sm={24} md={24} lg={11} xl={11}>
                            <Form.Item name="email" label={t('user.userForm.email.title')}>
                                <Input
                                    placeholder={t('user.userForm.email.placeholder')}
                                    disabled={true}
                                />
                            </Form.Item>

                            <Form.Item
                                label={t('user.userForm.name.title')}
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: t('user.userForm.name.required')
                                    }
                                ]}
                            >
                                <Input placeholder={t('user.userForm.name.placeholder')} />
                            </Form.Item>

                            <Form.Item label={t('user.userForm.address.title')} name="address">
                                <Input placeholder={t('user.userForm.address.placeholder')} />
                            </Form.Item>

                            <Form.Item
                                name="phoneNumber"
                                label={t('user.userForm.phoneNumber.title')}
                                rules={[
                                    {
                                        pattern: '^(0047|\\+47|47)?[2-9]\\d{7}$',
                                        message: t('user.userForm.phoneNumber.requiredFormat')
                                    }
                                ]}
                            >
                                <Input
                                    style={{
                                        width: '100%'
                                    }}
                                    placeholder={t('user.userForm.phoneNumber.placeholder')}
                                />
                            </Form.Item>

                            <Form.Item
                                name="organizationId"
                                label={t('user.userForm.company.title')}
                                rules={[
                                    {
                                        required: true,
                                        message: t('user.userForm.company.required')
                                    }
                                ]}
                            >
                                <FDSelection
                                    placeholder={t('user.userForm.company.placeholder')}
                                    listSelectItem={companiesSelection}
                                    onChange={handleCompanyChange}
                                />
                            </Form.Item>

                            <Form.Item
                                name="avatar"
                                label={t('user.userForm.avatar.title')}
                                valuePropName="fileList"
                                getValueFromEvent={normFile}
                                extra="*.png, *.jpg"
                            >
                                <FDUploadImage
                                    isAvatar={true}
                                    uploadedHandler={(url) => form.setFieldValue('avatar', url)}
                                    url={user?.avatar}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={24} md={24} lg={13} xl={13}>
                            {companyTypes?.includes(COMPANY_TYPE.FARMER) && (
                                <>
                                    <Divider orientation="left">
                                        {t('user.userForm.assignSitePen.title')}
                                    </Divider>

                                    <Form.Item name="penIds">
                                        <FDTreeTransfer
                                            treeData={treeData}
                                            selectedKeys={penIds}
                                            onSelectedKeysChange={(selectedKeys) =>
                                                setPenIds(selectedKeys)
                                            }
                                            messageForEmptyTreeData={t('message.noSiteAndPen')}
                                        />
                                    </Form.Item>
                                </>
                            )}

                            {companyTypes?.includes(COMPANY_TYPE.SERVICE_BOAT) && (
                                <>
                                    <Divider orientation="left">
                                        {t('user.userForm.assignServiceBoat.title')}
                                    </Divider>

                                    <Form.Item name="penIds">
                                        <Card className="tree-transfer">
                                            <Tree
                                                checkable={true}
                                                onCheck={(selectedKeys) =>
                                                    setExternalServiceBoatIds(selectedKeys)
                                                }
                                                checkedKeys={externalServiceBoatIds}
                                                treeData={serviceBoatSelectionData}
                                            />
                                        </Card>
                                    </Form.Item>
                                </>
                            )}
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </Modal>
    );
};

UserForm.propTypes = {
    open: PropTypes.bool,
    user: PropTypes.object,
    onClose: PropTypes.func
};

export default UserForm;
