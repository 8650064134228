import {
    COMPANY_CONFIG,
    COMPANY_TYPE,
    MORTALITY_CONFIG,
    NOTIFICATION_CONFIG
} from 'common/constants';
import { roundTwoDigits } from 'common/utils';

export const createMortalityConfigs = (
    firstThreshold = 50,
    secondThreshold = 100,
    difference = 0.01
) => {
    return [
        {
            key: MORTALITY_CONFIG.ACCEPTABLE.key,
            color: MORTALITY_CONFIG.ACCEPTABLE.color,
            from: null,
            to: firstThreshold
        },
        {
            key: MORTALITY_CONFIG.UNWANTED.key,
            color: MORTALITY_CONFIG.UNWANTED.color,
            from: firstThreshold + difference,
            to: secondThreshold - difference
        },
        {
            key: MORTALITY_CONFIG.UNACCEPTABLE.key,
            color: MORTALITY_CONFIG.UNACCEPTABLE.color,
            from: secondThreshold,
            to: null
        }
    ];
};

export const createNotificationConfigs = (
    firstThreshold = 5,
    secondThreshold = 10,
    difference = 0.01
) => {
    return [
        {
            key: NOTIFICATION_CONFIG.BELOW_AVERAGE.key,
            color: NOTIFICATION_CONFIG.BELOW_AVERAGE.color,
            from: null,
            to: firstThreshold
        },
        {
            key: NOTIFICATION_CONFIG.AVERAGE.key,
            color: NOTIFICATION_CONFIG.AVERAGE.color,
            from: firstThreshold + difference,
            to: secondThreshold - difference
        },
        {
            key: NOTIFICATION_CONFIG.ABOVE_AVERAGE.key,
            color: NOTIFICATION_CONFIG.ABOVE_AVERAGE.color,
            from: secondThreshold,
            to: null
        }
    ];
};

export const createCompanyConfigs = (req) => {
    if (!req.companyTypeNames.includes(COMPANY_TYPE.FARMER)) return;

    const mortalityLevelConfig = {
        name: COMPANY_CONFIG.MORTALITY_LEVEL,
        value: createMortalityConfigs(
            roundTwoDigits(req.firstMortalityLevel),
            roundTwoDigits(req.secondMortalityLevel)
        )
    };

    const mortalityTrendConfig = {
        name: COMPANY_CONFIG.MORTALITY_TREND,
        value: createMortalityConfigs(
            roundTwoDigits(req.firstMortalityTrend),
            roundTwoDigits(req.secondMortalityTrend)
        )
    };

    const notificationConfig = {
        name: COMPANY_CONFIG.AVERAGE_NOTIFICATION,
        value: createNotificationConfigs(
            roundTwoDigits(req.firstAverageNotification),
            roundTwoDigits(req.secondAverageNotification)
        )
    };

    return [mortalityLevelConfig, mortalityTrendConfig, notificationConfig];
};

export const extractCompanyConfig = (company) => {
    if (!company.companyTypeNames.includes(COMPANY_TYPE.FARMER)) return;

    const getConfigValue = (configName, key, property) => {
        return company.companyConfigs
            ?.find((config) => config.name === configName)
            ?.value.find((config) => config.key === key)?.[property];
    };

    return {
        firstMortalityLevel: getConfigValue(
            COMPANY_CONFIG.MORTALITY_LEVEL,
            MORTALITY_CONFIG.ACCEPTABLE.key,
            'to'
        ),
        secondMortalityLevel: getConfigValue(
            COMPANY_CONFIG.MORTALITY_LEVEL,
            MORTALITY_CONFIG.UNACCEPTABLE.key,
            'from'
        ),
        firstMortalityTrend: getConfigValue(
            COMPANY_CONFIG.MORTALITY_TREND,
            MORTALITY_CONFIG.ACCEPTABLE.key,
            'to'
        ),
        secondMortalityTrend: getConfigValue(
            COMPANY_CONFIG.MORTALITY_TREND,
            MORTALITY_CONFIG.UNACCEPTABLE.key,
            'from'
        ),
        firstAverageNotification: getConfigValue(
            COMPANY_CONFIG.AVERAGE_NOTIFICATION,
            NOTIFICATION_CONFIG.BELOW_AVERAGE.key,
            'to'
        ),
        secondAverageNotification: getConfigValue(
            COMPANY_CONFIG.AVERAGE_NOTIFICATION,
            NOTIFICATION_CONFIG.ABOVE_AVERAGE.key,
            'from'
        )
    };
};
