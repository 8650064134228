import { DribbbleOutlined } from '@ant-design/icons';
import { Card, Col, Row, Tree } from 'antd';
import PropTypes from 'prop-types';
import 'antd/dist/antd.min.css';
import './FDTreeTransfer.scss';

export const renderTreeNodes = (treeData, selectedKeys) => {
    const filteredTree = treeData
        .map((data) => ({
            ...data,
            children: data.children.filter((child) => selectedKeys.includes(child.key))
        }))
        .filter((data) => data.children.length);

    return filteredTree.map((item) => {
        if (item.children) {
            return (
                <Tree.TreeNode title={item.title} key={item.key} dataRef={item} checked>
                    {item.children.map((subItem) => (
                        <Tree.TreeNode
                            title={subItem.title}
                            key={subItem.key}
                            dataRef={subItem}
                            checked
                            switcherIcon={<DribbbleOutlined />}
                        />
                    ))}
                </Tree.TreeNode>
            );
        } else {
            return (
                <Tree.TreeNode
                    title={item.title}
                    key={item.key}
                    dataRef={item}
                    switcherIcon={<DribbbleOutlined />}
                />
            );
        }
    });
};

const FDTreeTransfer = ({
    treeData = [],
    selectedKeys = [],
    messageForEmptyTreeData = '',
    onSelectedKeysChange = () => {}
}) => {
    const onCheck = (selectedKeys) => {
        onSelectedKeysChange(
            selectedKeys.filter((key) => !treeData.find((node) => node.key === key))
        );
    };

    return (
        <div className="tree-transfer">
            <Row type="flex" gutter={20}>
                <Col span={12}>
                    <Card className="h-full">
                        {treeData.length > 0 ? (
                            <Tree
                                checkable={true}
                                defaultExpandAll={false}
                                onCheck={onCheck}
                                checkedKeys={selectedKeys}
                                treeData={treeData}
                            />
                        ) : (
                            <p className="text-center text-sky-800">{messageForEmptyTreeData}</p>
                        )}
                    </Card>
                </Col>
                <Col span={12}>
                    <Card>
                        <Tree defaultExpandAll={true}>
                            {renderTreeNodes(treeData, selectedKeys)}
                        </Tree>
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

FDTreeTransfer.propTypes = {
    treeData: PropTypes.array,
    selectedKeys: PropTypes.array,
    messageForEmptyTreeDate: PropTypes.string,
    onSelectedKeysChange: PropTypes.func
};

export default FDTreeTransfer;
